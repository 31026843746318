import * as React from "react";
import Faq from "containers/Faq";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import Seo from "components/seo";

const ContactPage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="FAQs" />
      <Faq />
    </Layout>
  );
};

export default ContactPage;
